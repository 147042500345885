<template>
    <div class="scrap-wrap">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="searchFormList"
          :form-data.sync="searchFormData"
          :searchType='1'
          @search="searchTable" />
      </div>
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入跟踪单号"
          :tags="functionHeader.tags"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          @search="$refs.mTable.setTableData(true)"
        >
        </FunctionHeader>
        <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="sourceCode" slot-scope="{ row }" :path="getDetailPath(row, 'view')" :text="row.sourceCode" />
        <div slot="sequenceNumber" slot-scope="{row }">
                    <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.sequenceNumber" placement="top-start">
                      <span class="mr-10">
                        {{ row.sequenceNumber }}
                    </span>
                    </el-tooltip>
                  </div>
          <div slot="action" slot-scope="{ row }">
            <el-button v-if="permission('Detail')" type="text" @click="viewDetail(row,'view')">详情</el-button>
            <el-divider v-if="permission('Detail')" direction="vertical" />
            <el-button v-if="permission('Handle')" type="text" @click="viewDetail(row,'handle')" :disabled="!row.canHandle">处理</el-button>
            <el-divider v-if="permission('Handle')" direction="vertical" />
            <el-button v-if="permission('Close')" type="text" @click="close(row)" :disabled="!row.canClose">关闭</el-button>
            <el-divider v-if="permission('Close')" direction="vertical" />
            <el-button v-if="permission('Follow')" type="text" @click="addFollowUp(row)">跟进记录</el-button>
          </div>
        </MTable>
      </div>
    <MDialog v-model="stationVisible" title="关闭" @onOk="submitCloseForm">
        <div >
          <el-input
          v-model="closeReason"
          placeholder="关闭理由"
          type="textarea"
          :rows="2"
          style="width: 100%"
        />
      </div>
    </MDialog>
    <MDialog v-model="followUpVisible" title="新增跟进记录" @onOk="submitFollowUpForm">
        <div style="padding-bottom: 10px;">
          <el-form ref="followUpBuild" :rules="rules" :model="followUpForm" label-width="80px" >
            <el-form-item label="改善状态" prop="improvedStatusId" tyle="margin-bottom: 20px">
              <el-select v-model="followUpForm.improvedStatusId" placeholder="请选择">
              <el-option
                v-for="item in improveStateList"
                :key="item.id"
                :label="item.improvedStatus"
                :value="item.id"
              />
            </el-select>
              </el-form-item>
            <el-form-item label="跟进记录" prop="followLog" style="margin-bottom: 20px">
              <el-input v-model="followUpForm.followLog" type="textarea" :rows="2"></el-input>
            </el-form-item>
            </el-form>
      </div>
    </MDialog>
    </div>
  </template>

  <script>
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { columns,getFunctionList } from '../columns'
  import api from '@/api/quality/quality-measure'
  import conventionMixin from '@/mixin/conventional-page'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import { divisionOptions, executionDepartment } from '@/views/plans/prod-plan-lists/data'
  import { searchFormList } from '../form-list'
  import { setTreeData } from '@/utils'
  import supplierApi from '@/api/information/unit'

  export default {
    name: 'QualityMeasureTrackingList',
    components: { NewPageOpen, SearchForm, FunctionHeader },
    mixins: [conventionMixin],
    data() {
      return {
        api,
        projectNumber:'',
        closeReason:'',
        divisionOptions,
        executionDepartment,
        params: {
          radio: 'QualityMeasureTracking'
        },
        columns,
        searchFormData: {},
        defaultSearchFormData: {
          procedureId: ''
        },
        titles: {
        add: '新增',
        modify: '分配部门',
        copy: '复制'
      },
      followUpForm:{},
        searchFormatData: [],
        functionHeader: {
          searchVal: '',
          tags: [
          ],
          exportName: '',
          exportParams: {},
        },
        formData: {},
        currentType: 'add',
        visible: false,
        searchKeyword: true,
        isPageLoaded:false,
        materialParams: { status: 1, page: 1, size: 10, search: '',materialsId:'' },
        planNodeList:[],
        principalLst:[],
        projectPlanId:'',
        showTwoTime:false,
        deliveryOneDate:'',
        deliveryTwoDate:[],
        searchFormList,
        isDisabled:false,
        stationList:[],
        stationVisible:false,
        followUpVisible:false,
        projectId:'',
        improveStateList:[],
        rules: {
        improvedStatusId: [{ required: true, message: '请选择改善状态', trigger: 'change' }],
        followLog: [{ required: true, message: '请输入跟进记录', trigger: 'blur' }]
      },
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 44
      }
    },
    watch: {
    'materialParams.search'(val) {
      this.formList[2].children[0].options.length = 0
      this.materialParams.page = 1
      this.$nextTick(() => {
        this.getFinishedMaterials(this.materialParams)
      })
    }
    },
    created() {
    // this.getFinishedMaterials(this.materialParams)
    this.getImproveStateList()
    this.getSupplierList()
    },
    activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    beforeMount() {
    getFunctionList({
      searchChange: this.searchChange,
      searchMaterialChange: this.searchMaterialChange
    })
  },
    mounted() {
      this.isPageLoaded = true
    },
    methods: {
      async getImproveStateList(){
        const res = await api.getImproveStateList()
        if(res){
          this.improveStateList = res
          this.searchFormList[3].children[0].options = res
        }
      },
      async getSupplierList(){
        const res = await supplierApi.getSupplierList()
        if(res){
          this.searchFormList[1].children[0].options = res
        }
      },
      close(row){
        this.closeReason = ''
        this.projectId = row.id
        this.stationVisible = true
      },
      addFollowUp(row){
        this.followUpForm = {}
        this.projectId = row.id
        this.followUpVisible = true
      },
    async submitCloseForm(callback) {
          const res = await api.closeSheet(
           {
            id:this.projectId,
            closeReason:this.closeReason
          }
          )
          if (res) {
            this.$message.success('操作成功')
            this.stationVisible = false
            this.$refs.mTable.setTableData()
          }
          callback(true)
    },
    // 新建跟进记录
   submitFollowUpForm(callback) {
      this.$refs.followUpBuild.validate(async(valid) => {
        if (valid) {
        const res = await api.addFollowLog(
        {
          trackingSheetId:this.projectId,
          improvedStatusId:this.followUpForm.improvedStatusId,
          followLog:this.followUpForm.followLog
        }
        )
        if (res) {
          this.$message.success('操作成功')
          this.followUpVisible = false
          this.$refs.mTable.setTableData()
        }
        callback(true)
        } else {
          callback()
        }
      })
        },
      searchChange(val){
       this.showTwoTime = val ? true : false
       this.$refs.formBuild.setForm({
        deliveryDate: ''
        });
       this.deliveryOneDate = ''
       this.deliveryTwoDate = []
    },
    searchMaterialChange(val) {
      if (!val) {
        this.materialParams.search = ''
        this.materialParams.materialsId = ''
        this.formList[2].children[0].options = []
        this.getFinishedMaterials(this.materialParams)
      }
    },
      async getTableData(condition, callback) {
        const { page } = condition
        const data = Object.assign({}, ...this.searchFormatData)
        const searchCondition = {
          code: this.functionHeader.searchVal,
          ...data
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          limit: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      viewDetail(row,type) {
        this.$router.push({  name: 'QualityMeasureTrackingDetail', query: { type: type,id:row.id}})
    },
    // 用户下拉触底分页加载
    finishedMaterialLoadMore() {
        if (this.formList[2].children[0].options.length === this.materialsTotal) return
        if (this.formList[2].children[0].options.length > this.materialsTotal) return [...this.formList[2].children[0].options = new Set(this.formList[2].children[0].options)]
        this.materialParams.page++
        this.getFinishedMaterials(this.materialParams)
    },
        // 获取成品物料
        async getFinishedMaterials(params) {
      const res = await processApi.getFinishedMaterials(params)
      if (res) {
        this.materialsTotal = res.total
        this.formList[2].children[0].options.push(...res.records || [])
      }
    },
      getDetailPath(row, command) {
        return { name: 'DefectiveProductJudgeDetail', query: { id: row.sourceId}}
      },
      searchTable(data,val) {
        if (val === 1) {
        this.searchFormData = {}
      }
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .scrap-wrap {
    width: 100%;
    padding-top: 10px;
    .bottom {
      padding: 10px;
    }
    .m-radio-group {
      margin-left: 10px;
    }
  }
  ::v-deep {
    .e-divider {
      margin: 0px 0 0 0;
    }
    .el-form-item {
      margin-bottom: 20px !important;
    }
    .el-form-item__content {
      height:32px;
    }
        .form-builder-wrap .el-row {
          display: block;
        }
  }
  </style>
