// 难易等级
export const difficultyLevelColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'level',
    label: '难易等级',
    sortable: false,
    hiddenSearch: true,
    form: { index: 0, tagName: 'el-input', required: true}
  },
  {
    prop: 'faultVerify',
    label: '故障确认（天）',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'el-input-number', props: { controlsPosition: 'right', min: 0, placeholder: '请输入故障确认（天）' }, required: false}
    // form: { index: 1, tagName: 'el-input', required: false}
  },
  {
    prop: 'containmentMeasure',
    label: '遏制确认（天）',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { index: 2, tagName: 'el-input-number', props: { controlsPosition: 'right', min: 0, placeholder: '请输入遏制确认（天）' }, required: false}
  },
  {
    prop: 'rootCause',
    label: '根本原因（天）',
    sortable: false,
    hiddenSearch: true,
    form: { index: 3, tagName: 'el-input-number', props: { controlsPosition: 'right', min: 0, placeholder: '请输入根本原因（天）' }, required: false}
  },
  {
    prop: 'tempMeasure',
    label: '临时措施（天）',
    sortable: false,
    hiddenSearch: true,
    form: { index: 4, tagName: 'el-input-number', props: { controlsPosition: 'right', min: 0, placeholder: '请输入临时措施（天）' }, required: false}
  },
  {
    prop: 'permanentMeasure',
    label: '永久措施（天）',
    sortable: false,
    hiddenSearch: true,
    form: { index: 5, tagName: 'el-input-number', props: { controlsPosition: 'right', min: 0, placeholder: '请输入永久措施（天）' }, required: false}
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 6, tagName: 'el-input', required: false}
  },
  
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 180
  }
]
// 问题等级
export const problemLevelColumns = [
    {
      prop: 'index',
      label: '序号',
      sortable: true,
      slotName: 'index',
      hiddenSearch: true,
      minWidth: 80
    },
    {
      prop: 'level',
      label: '问题等级',
      sortable: false,
      hiddenSearch: true,
      form: { index: 0, tagName: 'el-input', required: true}
    },
    {
      prop: 'score',
      label: '问题分值',
      sortable: false,
      hiddenSearch: true,
      form: { index: 1, tagName: 'el-input-number', props: { controlsPosition: 'right', min: 0, placeholder: '请输入问题分值' }, required: false}
    },
    {
      prop: 'complaintDegree',
      label: '抱怨程度',
      sortable: false,
      hiddenSearch: true,
      minWidth: 150,
      form: { index: 2, tagName: 'el-input', required: false}
    },
    {
      prop: 'discovery',
      label: '发现难度',
      sortable: false,
      hiddenSearch: true,
      form: { index: 3, tagName: 'el-input', required: false}
    },
    {
      prop: 'faultType',
      label: '故障类别',
      sortable: false,
      hiddenSearch: true,
      form: { index: 4, tagName: 'el-input', required: false}
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true,
      form: { index: 5, tagName: 'el-input', required: false}
    },
    
    {
      prop: 'creator',
      label: '创建人',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'createTime',
      label: '创建时间',
      sortable: false,
      hiddenSearch: true
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 180
    }
  ]
  // 问题分类
  export const problemClassColumns = [
    {
      prop: 'index',
      label: '序号',
      sortable: true,
      slotName: 'index',
      hiddenSearch: true,
      minWidth: 80
    },
    {
      prop: 'problemClass',
      label: '问题分类',
      sortable: false,
      hiddenSearch: true,
      form: { index: 0, tagName: 'el-input', required: true}
    },
    {
      prop: 'description',
      label: '分类描述',
      sortable: false,
      hiddenSearch: true,
      form: { index: 1, tagName: 'el-input', required: false}
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true,
      form: { index: 2, tagName: 'el-input', required: false}
    },
    {
      prop: 'creator',
      label: '创建人',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'createTime',
      label: '创建时间',
      sortable: false,
      hiddenSearch: true
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 180
    }
  ]
    // 改善状态
    export const improveStateColumns = [
      {
        prop: 'index',
        label: '序号',
        sortable: true,
        slotName: 'index',
        hiddenSearch: true,
        minWidth: 80
      },
      {
        prop: 'improvedStatus',
        label: '改善状态',
        sortable: false,
        hiddenSearch: true,
        form: { index: 0, tagName: 'el-input', required: true}
      },
      {
        prop: 'definition',
        label: '定义',
        sortable: false,
        hiddenSearch: true,
        form: { index: 1, tagName: 'el-input', required: false}
      },
      {
        prop: 'remark',
        label: '备注',
        sortable: false,
        hiddenSearch: true,
        form: { index: 2, tagName: 'el-input', required: false}
      },
      
      {
        prop: 'creator',
        label: '创建人',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'createTime',
        label: '创建时间',
        sortable: false,
        hiddenSearch: true
      },
      {
        slotName: 'action',
        label: '操作',
        fixed: 'right',
        sortable: false,
        hiddenSearch: true,
        width: 180
      }
    ]

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

// 质量措施跟踪单列表
export const columns = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'code',
      label: '跟踪单号',
      sortable: false,
      hiddenSearch: true,
      width: 160
    },
    {
      prop: 'improvedStatusName',
      label: '改善状态',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'sequenceNumber',
      label: '序列号',
      sortable: false,
      hiddenSearch: true,
      slotName:'sequenceNumber',
      minWidth: 120
    },
    {
      prop: 'source',
      label: '来源对象',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'sourceCode',
      label: '来源单号',
      sortable: false,
      hiddenSearch: true,
      slotName:'sourceCode',
      width: 160
    },
    {
      prop: 'procedureName',
      label: '工序',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
   
    {
        prop: 'materialsCode',
        label: '物料编码',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
        prop: 'materialsName',
        label: '物料名称',
        sortable: false,
        hiddenSearch: true
      },
     
    {
      prop: 'materialsSpecifications',
      label: '物料规格',
      hiddenSearch: true,
      sortable: false
    },
    {
        prop: 'supplierName',
        label: '供应商',
        hiddenSearch: true,
        sortable: false,
        minWidth: 100
      },
    {
        prop: 'problemLevelName',
        label: '问题等级',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'difficultyLevelName',
        label: '难易等级',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'problemDesc',
        label: '问题描述',
        hiddenSearch: true,
        sortable: false
      },
      // {
      //   prop: 'picture',
      //   label: '图片',
      //   sortable: false,
      //   hiddenSearch: true,
      //   slotName:'picture',
      //   minWidth: 120
      // },
      {
        prop: 'actualClosingTime',
        label: '关闭日期',
        hiddenSearch: true,
        sortable: false
      },
    {
      prop: 'closeReason',
      label: '关闭理由',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'remark',
      label: '备注',
      hiddenSearch: true,
      sortable: false
    },
    {
        prop: 'creator',
        label: '创建人',
        hiddenSearch: true,
        sortable: false
      },
      
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      slotName: 'action',
      label: '操作',
      sortable: false,
      hiddenSearch: true,
      width: 240,
      fixed: 'right'
    }
  ]