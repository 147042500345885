import _ from 'lodash'
import { problemLevelColumns,problemClassColumns,improveStateColumns,difficultyLevelColumns } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(problemLevelColumns)

export const problemLevelFormList = transformColumnsToForm(newColumns)
export const problemClassFormList = transformColumnsToForm(problemClassColumns)
export const improveStateFormList = transformColumnsToForm(improveStateColumns)
export const difficultyLevelFormList = transformColumnsToForm(difficultyLevelColumns)

let functionList = {}
export const getPlanFunctionList = val => {
  functionList = val
}


// 质量措施跟踪单搜索字段
export const searchFormList = [
  {
    colSpan: 5,
    key: 'sequenceNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '请输入序列号'
    }
  },
  {
    colSpan: 5,
    key: 'supplierId',
    tagName: 'el-select',
      props: {
        placeholder: '请选择供应商'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: []
        }
      ]
  },
  {
    colSpan: 5,
    key: 'materialsSearch',
    tagName: 'el-input',
    attrs: {
      placeholder: '请输入物料编码/名称/规格'
    }
  },
  {
      colSpan:5,
      key: 'improvedStatusId',
      tagName: 'el-select',
      props: {
        placeholder: '请选择改善状态'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'improvedStatus',
            value: 'id'
          },
          options: []
        }
      ]
    }
]
