<template>
  <div class="scrap-wrap">
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :tags="functionHeader.tags"
        :hidden-search="true"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :import-name="functionHeader.importName"
        @search="$refs.mTable.setTableData(true)"
      >
      </FunctionHeader>
      <MTable ref="mTable" :columns="problemLevelColumns" :height="height" :set-data-method="getTableData" >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
     

        <div slot="action" slot-scope="{ row }">
          <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row,'modify')">修改</el-button>
          <el-divider v-if="permission('Modify')" direction="vertical" />
          <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
        </div>
      </MTable>
    </div>
    <MDialog v-model="visible" :title="titles[currentType]" @onOk="submitForm">
    <MFormBuilder ref="formBuild" :form-data="formData" :form-list="problemLevelFormList" >
    </MFormBuilder>
  </MDialog>
  </div>
</template>

<script>
import { problemLevelColumns } from './columns'
import api from '@/api/quality/quality-measure'
import conventionMixin from '@/mixin/conventional-page'
import FunctionHeader from '@/components/FunctionHeader/index'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import {problemLevelFormList } from './form-list'

export default {
  name: 'ProblemLevelList',
  components: { NewPageOpen, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      projectNumber:'',
      problemLevelFormList,
      params: {
        radio: 'ProblemLevel'
      },
      problemLevelColumns,
      searchFormData: {},
      defaultSearchFormData: {
        procedureId: ''
      },
      titles: {
      add: '新增',
      modify: '修改',
      copy: '复制'
    },
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
        {
          hidden: !this.permission('Add'),
          tagName: 'el-button',
          props: { type: 'primary', size: 'small' },
          style: { width: 'auto', background: '#607FFF' },
          innerText: '新增',
          on: {
            click: this.add
          }
        }
        ],
        exportName: '',
        exportParams: {},
      },
      formData: {},
      currentType: 'add',
      visible: false,
      searchKeyword: true,
      planNodeList:[],
      principalLst:[],
      projectPlanId:'',
      showTwoTime:false,
      isDisabled:false,
      stationList:[]
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235
    }
  },
  watch: {
  },
  created() {

  },
  mounted() {
  },
  methods: {
   
    async getTableData(condition, callback) {
      const { page } = condition
      const data = Object.assign({}, ...this.searchFormatData)
      const searchCondition = {
        projectNumber: this.functionHeader.searchVal,
        ...data
      }
      this.functionHeader.exportParams = searchCondition
      const res = await this.api[`get${this.params.radio}ListPage`]({
        page: page.currentPage,
        limit: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },


  createSetData(raw) {
    return {
      level:raw.level,
      score:raw.score,
      complaintDegree:raw.complaintDegree,
      quantity:raw.quantity,
      discovery:raw.discovery,
      faultType:raw.faultType,
      remark:raw.remark
    }
  },
  createResetData(raw) {
    const obj = {
      id: raw.id
    }
    return Object.assign(this.createSetData(raw), obj)
  }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;
  .bottom {
    padding: 10px;
  }
}
::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }
  .el-form-item__content {
    height:32px;
  }
      .form-builder-wrap .el-row {
        display: block;
      }
}
</style>
