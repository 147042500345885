<template>
    <div class="fault-wrap common-tab">
      <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
        <el-radio-button label="1">待处理</el-radio-button>
        <el-radio-button label="2">已处理</el-radio-button>
      </el-radio-group>
      <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data="searchFormData"
        @search="searchTable" 
        :searchType='1'
      />
    </div>
      <div class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="输入单号搜索"
        :tags="functionHeader.tags"  
        style="padding: 10px 0 10px 0"
        @search="$refs.mTable.setTableData(true)"
      >
      </FunctionHeader>
      <MTable ref="mTable" :columns="getColumns" :height="height" :set-data-method="getTableData">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <NewPageOpen slot="projectName" slot-scope="{ row }" :path="{name: mode ? 'planOrdersDetail' : 'ProjectManagementDetail',query:{id:row.projectId}}" :text="row.projectName" />
        <NewPageOpen slot="sourceNumber" slot-scope="{ row }" :path="{name: row.sourceObject === '来料检' ? 'QualityIncomingTaskDetail' : row.sourceObject === '异常上报' ?  'ExceptionDetail' : 'QualityTaskDetail',query:{id: row.sourceId, type:'view'}}" :text="row.sourceNumber" />
        <div slot="inspectionType" slot-scope="{ row }">{{ inspectionTypeNames[row.businessType - 1] }}</div>
        <div slot="status" slot-scope="{ row }">
          {{ allStatus[row.status - 1] }}
        </div>
        <div slot="isKeyMaterials" slot-scope="{ row }">{{ row.isKeyMaterials ? '是' : '否'}}</div>
        <div slot="isSettingVariable" slot-scope="{ row }">{{ row.settingVariable ? '是' : '否'}}</div>
        <div slot="sequenceNumber" slot-scope="{row }">
          <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.sequenceNumber" placement="top-start">
            <span class="mr-10">
              {{ row.sequenceNumber }}
          </span>
          </el-tooltip>
        </div>
        <div slot="action" slot-scope="{ row }" style="z-index: 100">
          <el-button v-if="permission('Detail')" type="text" @click="viewDetail(row)">详情</el-button>
          <el-button v-if="permission('AddQualityMeasureSheet')" type="text" @click="addQualityMeasureTracking(row)">创建质量措施单</el-button>
        </div>
      </MTable>
      </div>
  
    </div>
  </template>
  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import api from '@/api/qualityManagement/defective-product-judge'
  import { columnsAll } from './columns'
  import { searchFormList } from './form-list'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import processApi from '@/api/information/production/process'
  import produceApi from '@/api/information/production/procedure'
  import papi from '@/api/projectManagement/project-hour-statistics'
  import conventionMixin from '@/mixin/conventional-page'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  
  export default {
    name: 'DefectiveProductJudgeList',
    components: { FunctionHeader,SearchForm,NewPageOpen },
    mixins: [conventionMixin],
    data() {
      return {
        api,
        searchFormData: {},
        searchFormDatas: [],
        keyword: '',
        selectKeyword: '1',
        functionHeader: {
          searchVal: ''
        },
        columnsAll,
        allStatus: ['待处理','已处理','已作废'],
        inspectionTypeNames: ['自制工序自检', '自制工序专检', '委外工序检验','来料检', '异常上报'],
        searchDatas: [],
        searchFormList,
        params: {
            radio: '1'
          },
          materialParams: { status: 0, page: 1, size: 10, search: '' },
          projectParams: {  page: 1, size: 10, search: '' },
          isPageLoaded:false
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 65
      },
      mode() {
      return this.$store.state.app.mode
    },
      getColumns(){
        if (this.mode === 1) {
        return this.columnsAll.map(item => {
      const newLabel = item.label ? item.label.replace(/项目/g, '订单') :''
      return { ...item, label: newLabel }
    })
      } else {
        return this.columnsAll
      }
    }
    },
    watch: {
  },
  activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    mounted() {
      this.isPageLoaded = true
      this.initPage()
      this.getProcedureList()
      this.searchFormList[3].props.placeholder = this.mode ? '请选择订单' : '请选择项目'
    },
    methods: {
      initPage() {
      // 绑定成品物料下拉加载指令
      this.searchFormList[1].directives[0].value = this.finishedMaterialLoadMore
      this.searchFormList[3].directives[0].value = this.projectLoadMore
      // 获取成品物料
      this.getMaterialList(this.materialParams)
      this.getProjectListPage(this.projectParams)
      // 绑定成品物料远程搜索
      this.searchFormList[1].props.remoteMethod = (val) => {
        this.materialParams.search = val
        this.materialParams.page = 1
        this.materialsTotal = 0
        this.searchFormList[1].children[0].options = []
        this.getMaterialList(this.materialParams)
      }
      this.searchFormList[3].props.remoteMethod = (val) => {
        this.projectParams.search = val
        this.projectParams.page = 1
        this.projectTotal = 0
        this.searchFormList[3].children[0].options = []
        this.getProjectListPage(this.projectParams)
      }
    },
      // 获取工序
      async getProcedureList() {
      const res = await produceApi.getProcedureList(this.defaultSearch)
      if (res) {
        this.searchFormList[2].children[0].options = res
      }
    },
    searchTable(data) {
      this.searchFormDatas = data
      this.$refs.mTable.setTableData(true)
    },
    // 物料
    async getMaterialList(params) {
      const res = await processApi.getFinishedMaterials(params)
      if (res) {
        this.materialsTotal = res.total
        this.searchFormList[1].children[0].options.push(...res.records || [])
      }
    },
    // 项目
    async getProjectListPage(params) {
      const res = await papi.getProjectListPage(params)
      if (res) {
        this.projectTotal = res.total
        this.searchFormList[3].children[0].options.push(...res.records || [])
      }
    },
    // 成品物料下拉触底分页加载
    finishedMaterialLoadMore() {
      if (this.searchFormList[1].children[0].options.length === this.materialsTotal) return
      if (this.searchFormList[1].children[0].options.length > this.materialsTotal) return [... this.searchFormList[1].children[0].options = new Set(this.searchFormList[1].children[0].options)]
      this.materialParams.page++
      this.getMaterialList(this.materialParams)
    },
    // 项目下拉触底分页加载
    projectLoadMore() {
      if (this.searchFormList[3].children[0].options.length === this.projectTotal) return
      if (this.searchFormList[3].children[0].options.length > this.projectTotal) return [... this.searchFormList[3].children[0].options = new Set(this.searchFormList[3].children[0].options)]
      this.projectParams.page++
      this.getProjectListPage(this.projectParams)
    },
      radioChange() {
        this.$refs.mTable.setTableData(true)
      },
      viewDetail(row) {
        this.$router.push({ name: 'DefectiveProductJudgeDetail', query: { id:row.id }})
      },
      addQualityMeasureTracking(row) {
        const mergedObj = Object.assign({}, row);
        this.$router.push({ name: 'QualityMeasureTrackingDetail', query: { type:'add',needInfo: JSON.stringify(mergedObj) }})
      },
      // 列表
      async getTableData(condition, callback) {
        const { page, order } = condition
        const data = Object.assign({}, ...this.searchFormDatas)
        const searchCondition = {
          number: this.functionHeader.searchVal,
          status:this.params.radio,
          ...data
        }
        const res = await api.getDefectProductJudgmentListPage({
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      }
    }
  }
  </script>
    <style scoped lang="scss">
     .fault-wrap{
        padding: 10px;
        .item-wrapper {
          > div {
            display: inline-flex;
            padding: 10px 5px 0 5px;
            align-items: center;
            font-size: 14px;
  
            .label {
              min-width: 115px;
            }
  
          }
        }
        .all-select {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .checkAll {
            margin-right: 20px;
          }
          .all-li {
            width: 80px;
            height: 26px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 1px solid #DDE3EB;
            margin-right: 15px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A4B3C6;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .active {
            color: #607FFF;
            border-color: #607FFF;
            background: #EFF2FF;
          }
        }
        .li-btn{
          // margin-right:10px;
          color:#607FFF;
          font-weight: 500;
        }
        .color-btns{
          color:#c4c4c4;
        }
        .searchs{
          padding-left: 0;
          padding-top: 0;
        }
        .input-select{
          width: 100px;
          display: block;
          }
        .input-with-select{
          width: 100%;
        }
        .all-operator{
          height: 32px;
          margin-left: 10px;
          ::v-deep{
            .el-button-group .el-button{
              height: 32px;
            }
          }
        }
     }
    </style>  