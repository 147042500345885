var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("来料检详情")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("来料检单号：" + _vm._s(_vm.detailData.code))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "检验批次：" + _vm._s(_vm.detailData.qualifiedBatchNumber)
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("批次数量：" + _vm._s(_vm.detailData.batchQuantity))
              ])
            ]),
            _vm.detailData.isEnableSequenceNumber
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v("序列号：" + _vm._s(_vm.detailData.sequenceNumber))
                  ])
                ])
              : _vm._e(),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "span",
                { staticClass: "bot-ti" },
                [
                  _vm._v("物料编码： "),
                  _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "materialsDetail",
                        query: { id: _vm.detailData.materialsId, type: "view" }
                      },
                      text: _vm.detailData.materialsCode
                    }
                  })
                ],
                1
              )
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("物料名称：" + _vm._s(_vm.detailData.materialsName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "物料规格：" +
                    _vm._s(_vm.detailData.materialsSpecifications || "-")
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "是否关键件：" +
                    _vm._s(_vm.detailData.isKeyMaterials ? "是" : "否")
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "是否免检：" +
                    _vm._s(_vm.detailData.isExemption ? "是" : "否")
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "收料单号：" +
                    _vm._s(_vm.detailData.materialsReceivedOrderCode)
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("采购单号：" + _vm._s(_vm.detailData.purchaseOrderCode))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("供应商：" + _vm._s(_vm.detailData.supplierName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "检验方案：" + _vm._s(_vm.detailData.inspectionSchemeName)
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("检验项数量：" + _vm._s(_vm.detailData.checkItemCount))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("计划检验人：" + _vm._s(_vm.detailData.executeUserName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "span",
                { staticClass: "bot-ti" },
                [
                  _vm._v("附件： "),
                  _vm._l(_vm.detailData.attachment, function(item) {
                    return _c(
                      "el-link",
                      {
                        key: item.id,
                        staticStyle: { "padding-left": "10px" },
                        attrs: { href: item.url, target: "_blank" }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  })
                ],
                2
              )
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _vm.type === "view"
                ? _c("span", { staticClass: "bot-ti" }, [
                    _vm._v("备注：" + _vm._s(_vm.detailData.remark || "-"))
                  ])
                : _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "bot-ti",
                          staticStyle: { "margin-bottom": "0px" }
                        },
                        [_vm._v("备注：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.detailData.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.detailData, "remark", $$v)
                          },
                          expression: "detailData.remark"
                        }
                      })
                    ],
                    1
                  )
            ]),
            _vm.detailData.result == 0
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v("处理意见：" + _vm._s(_vm.detailData.suggestion))
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("检验统计")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _vm.type === "view" || _vm.detailData.isEnableSequenceNumber
                ? _c("span", { staticClass: "bot-ti" }, [
                    _vm._v(
                      "合格数量：" +
                        _vm._s(
                          _vm.detailData.qualifiedQuantity != null
                            ? _vm.detailData.qualifiedQuantity
                            : "-"
                        )
                    )
                  ])
                : _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "bot-ti",
                          staticStyle: { "margin-bottom": "0px" }
                        },
                        [_vm._v("合格数量：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.detailData.qualifiedQuantity,
                          callback: function($$v) {
                            _vm.$set(_vm.detailData, "qualifiedQuantity", $$v)
                          },
                          expression: "detailData.qualifiedQuantity"
                        }
                      })
                    ],
                    1
                  )
            ]),
            _vm.detailData.isEnableSequenceNumber
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v(
                      "合格序列号：" +
                        _vm._s(_vm.detailData.qualifiedSequenceNumber || "-")
                    )
                  ])
                ])
              : _vm._e(),
            _c("el-col", { attrs: { span: 6 } }, [
              _vm.type === "view" || _vm.detailData.isEnableSequenceNumber
                ? _c("span", { staticClass: "bot-ti" }, [
                    _vm._v(
                      "不良数量：" +
                        _vm._s(
                          _vm.detailData.defectQuantity != null
                            ? _vm.detailData.defectQuantity
                            : "-"
                        )
                    )
                  ])
                : _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "bot-ti",
                          staticStyle: { "margin-bottom": "0px" }
                        },
                        [_vm._v("不良数量：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.detailData.defectQuantity,
                          callback: function($$v) {
                            _vm.$set(_vm.detailData, "defectQuantity", $$v)
                          },
                          expression: "detailData.defectQuantity"
                        }
                      })
                    ],
                    1
                  )
            ]),
            _vm.detailData.isEnableSequenceNumber
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v(
                      "不良序列号：" +
                        _vm._s(_vm.detailData.defectSequenceNumber || "-")
                    )
                  ])
                ])
              : _vm._e(),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "不良批次：" + _vm._s(_vm.detailData.defectBatchNumber || "-")
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "检验数量：" +
                    _vm._s(_vm.detailData.inspectionQuantity || "-")
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "检验人：" + _vm._s(_vm.detailData.inspectionUserName || "-")
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "开始检验时间：" + _vm._s(_vm.detailData.startTime || "-")
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("完成检验时间：" + _vm._s(_vm.detailData.endTime || "-"))
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c(
          "div",
          {
            staticClass: "tables",
            staticStyle: { padding: "10px", "margin-bottom": "50px" }
          },
          [
            _vm.projectLength > 0
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "padding-bottom": "10px"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-arrow-left",
                        style: {
                          color: _vm.currentPage == 1 ? "#C0C4CC" : "#303133",
                          cursor:
                            _vm.currentPage === 1 ? "not-allowed" : "pointer"
                        },
                        on: {
                          click: function($event) {
                            return _vm.beforePage()
                          }
                        }
                      }),
                      _vm._l(_vm.detailData.sequenceNumberItemList, function(
                        item,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            key: item.sequenceNumber,
                            staticStyle: {
                              display: "inline-block",
                              "min-width": "35px",
                              "text-align": "center",
                              padding: "0 4px"
                            },
                            style: {
                              color:
                                _vm.currentPage == index + 1
                                  ? "#303133"
                                  : "#C0C4CC"
                            }
                          },
                          [_vm._v(_vm._s(item.sequenceNumber))]
                        )
                      }),
                      _c("i", {
                        staticClass: "el-icon-arrow-right",
                        style: {
                          color:
                            _vm.currentPage == _vm.projectLength
                              ? "#C0C4CC"
                              : "#303133",
                          cursor:
                            _vm.currentPage == _vm.projectLength
                              ? "not-allowed"
                              : "pointer"
                        },
                        on: {
                          click: function($event) {
                            return _vm.nextPage()
                          }
                        }
                      })
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _c(
              "el-tabs",
              {
                ref: "sortTab",
                attrs: { id: "sortTabs", type: "card" },
                model: {
                  value: _vm.currentTabs,
                  callback: function($$v) {
                    _vm.currentTabs = $$v
                  },
                  expression: "currentTabs"
                }
              },
              _vm._l(_vm.projectList, function(item, index) {
                return _c("el-tab-pane", {
                  key: index,
                  attrs: {
                    label: item.inspectionGroupName || "请选择",
                    name: "tab" + index
                  }
                })
              }),
              1
            ),
            _vm._l(_vm.newprojectList[_vm.currentPage - 1], function(
              card,
              cardIndex
            ) {
              return _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: "tab" + cardIndex === _vm.currentTabs,
                      expression: "('tab' + cardIndex) === currentTabs"
                    }
                  ],
                  key: cardIndex,
                  staticClass: "card-custom"
                },
                [
                  _c("MTable", {
                    ref: "mTable",
                    refInFor: true,
                    attrs: {
                      "show-page": false,
                      height: 400,
                      columns: _vm.columnsDetail,
                      data: card.inspectionItemList
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "attachments",
                          fn: function(ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return _c("div", {}, [
                              _vm.type === "view"
                                ? _c("div", [
                                    row.attachments
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#6B8AFF",
                                              cursor: "pointer"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.viewPicture(row)
                                              }
                                            }
                                          },
                                          [_vm._v("查看")]
                                        )
                                      : _c("div", [_vm._v("-")])
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "el-upload",
                                        _vm._b(
                                          {
                                            ref: "upload",
                                            refInFor: true,
                                            attrs: {
                                              "file-list": row.attachments,
                                              accept: ".png,.jpg",
                                              "on-remove": function(file) {
                                                return _vm.fileRemove(
                                                  file,
                                                  cardIndex,
                                                  $index
                                                )
                                              }
                                            }
                                          },
                                          "el-upload",
                                          _vm.uploadProps,
                                          false
                                        ),
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#6B8AFF",
                                                cursor: "pointer"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.getRowIndex(
                                                    cardIndex,
                                                    $index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("上传")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                            ])
                          }
                        },
                        {
                          key: "measuredValue",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm.type === "view"
                                ? _c(
                                    "span",
                                    {
                                      style: {
                                        color: _vm.fixData(row) ? "#E02020" : ""
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm._f("fixValue")(row)))]
                                  )
                                : _c(
                                    "div",
                                    [
                                      row.parameterType === 0
                                        ? _c("el-input-number", {
                                            class:
                                              row.standardValue != null
                                                ? row.measuredValue >
                                                    row.upperLimit +
                                                      row.standardValue ||
                                                  row.measuredValue <
                                                    row.standardValue -
                                                      row.lowerLimit
                                                  ? "red-group"
                                                  : ""
                                                : "",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "controls-position": "right"
                                            },
                                            on: {
                                              change: function(val) {
                                                _vm.handleChange(val, row)
                                              }
                                            },
                                            model: {
                                              value: row.measuredValue,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "measuredValue",
                                                  $$v
                                                )
                                              },
                                              expression: "row.measuredValue"
                                            }
                                          })
                                        : _vm._e(),
                                      row.parameterType === 1
                                        ? _c("el-input", {
                                            model: {
                                              value: row.measuredValue,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "measuredValue",
                                                  $$v
                                                )
                                              },
                                              expression: "row.measuredValue"
                                            }
                                          })
                                        : _vm._e(),
                                      row.parameterType === 2
                                        ? _c(
                                            "el-select",
                                            {
                                              model: {
                                                value: row.measuredValue,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "measuredValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.measuredValue"
                                              }
                                            },
                                            _vm._l(row.possibleValue, function(
                                              val
                                            ) {
                                              return _c("el-option", {
                                                key: val,
                                                attrs: {
                                                  label: val,
                                                  value: val
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e(),
                                      row.parameterType === 3
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: { multiple: "" },
                                              model: {
                                                value: row.measuredValue,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "measuredValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.measuredValue"
                                              }
                                            },
                                            _vm._l(row.possibleValue, function(
                                              val
                                            ) {
                                              return _c("el-option", {
                                                key: val,
                                                attrs: {
                                                  label: val,
                                                  value: val
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e(),
                                      row.parameterType === 4
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: { clearable: "" },
                                              model: {
                                                value: row.measuredValue,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "measuredValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.measuredValue"
                                              }
                                            },
                                            _vm._l(_vm.resultList, function(
                                              val
                                            ) {
                                              return _c("el-option", {
                                                key: val.id,
                                                attrs: {
                                                  label: val.name,
                                                  value: val.id
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                            ])
                          }
                        },
                        {
                          key: "remark",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _vm.type === "view"
                                  ? _c("span", [_vm._v(_vm._s(row.remark))])
                                  : _c("el-input", {
                                      model: {
                                        value: row.remark,
                                        callback: function($$v) {
                                          _vm.$set(row, "remark", $$v)
                                        },
                                        expression: "row.remark"
                                      }
                                    })
                              ],
                              1
                            )
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            }),
            _vm._l(_vm.detailData.sequenceNumberItemList, function(
              item,
              index
            ) {
              return _c("div", { key: item.sequenceNumber }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: index + 1 === _vm.currentPage,
                        expression: "index + 1 === currentPage"
                      }
                    ],
                    staticClass: "card-bot",
                    staticStyle: { "align-items": "center" }
                  },
                  [
                    _vm.type === "view"
                      ? _c(
                          "span",
                          {
                            staticClass: "bot-ti",
                            staticStyle: { "margin-bottom": "0px" }
                          },
                          [
                            _vm._v(
                              "合格状态：" +
                                _vm._s(
                                  item.sequenceNumberResult === 0
                                    ? "不合格"
                                    : item.sequenceNumberResult === 1
                                    ? "合格"
                                    : "-"
                                )
                            )
                          ]
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "bot-ti",
                                staticStyle: { "margin-bottom": "0px" }
                              },
                              [_vm._v("合格状态：")]
                            ),
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: item.sequenceNumberResult,
                                  callback: function($$v) {
                                    _vm.$set(item, "sequenceNumberResult", $$v)
                                  },
                                  expression: "item.sequenceNumberResult"
                                }
                              },
                              _vm._l(_vm.resultList, function(val) {
                                return _c("el-option", {
                                  key: val.name,
                                  attrs: { label: val.name, value: val.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                  ]
                )
              ])
            })
          ],
          2
        )
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(_vm.type !== "view" ? "取 消" : "返 回"))
            ]),
            _vm.type !== "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.save(0)
                      }
                    }
                  },
                  [_vm._v("暂 存")]
                )
              : _vm._e(),
            _vm.type !== "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.save(1)
                      }
                    }
                  },
                  [_vm._v("完成检验")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c(
        "MDialog",
        {
          attrs: {
            title: "现场图片",
            width: "600px",
            height: "500px",
            "show-footer": false
          },
          on: {
            onOk: function($event) {
              _vm.pictureVisible = false
            }
          },
          model: {
            value: _vm.pictureVisible,
            callback: function($$v) {
              _vm.pictureVisible = $$v
            },
            expression: "pictureVisible"
          }
        },
        _vm._l(_vm.formatArr(_vm.imgList), function(item) {
          return _c("el-image", {
            key: item,
            staticClass: "table-row-img",
            staticStyle: { "padding-left": "10px" },
            attrs: { src: item, "preview-src-list": _vm.formatArr(_vm.imgList) }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }