var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fault-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "1" } }, [_vm._v("待处理")]),
          _c("el-radio-button", { attrs: { label: "2" } }, [_vm._v("已处理")])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForm", {
            ref: "searchForm",
            attrs: {
              "form-list-extend": _vm.searchFormList,
              "form-data": _vm.searchFormData,
              searchType: 1
            },
            on: { search: _vm.searchTable }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("FunctionHeader", {
            staticStyle: { padding: "10px 0 10px 0" },
            attrs: {
              "search-title": "输入单号搜索",
              tags: _vm.functionHeader.tags
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.getColumns,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "projectName",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: _vm.mode
                          ? "planOrdersDetail"
                          : "ProjectManagementDetail",
                        query: { id: row.projectId }
                      },
                      text: row.projectName
                    }
                  })
                }
              },
              {
                key: "sourceNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name:
                          row.sourceObject === "来料检"
                            ? "QualityIncomingTaskDetail"
                            : row.sourceObject === "异常上报"
                            ? "ExceptionDetail"
                            : "QualityTaskDetail",
                        query: { id: row.sourceId, type: "view" }
                      },
                      text: row.sourceNumber
                    }
                  })
                }
              },
              {
                key: "inspectionType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(_vm.inspectionTypeNames[row.businessType - 1])
                    )
                  ])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(_vm.allStatus[row.status - 1]) + " ")
                  ])
                }
              },
              {
                key: "isKeyMaterials",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.isKeyMaterials ? "是" : "否"))
                  ])
                }
              },
              {
                key: "isSettingVariable",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.settingVariable ? "是" : "否"))
                  ])
                }
              },
              {
                key: "sequenceNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            "popper-class": "sequence-tooltip",
                            content: row.sequenceNumber,
                            placement: "top-start"
                          }
                        },
                        [
                          _c("span", { staticClass: "mr-10" }, [
                            _vm._v(" " + _vm._s(row.sequenceNumber) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    { staticStyle: { "z-index": "100" } },
                    [
                      _vm.permission("Detail")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.viewDetail(row)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          )
                        : _vm._e(),
                      _vm.permission("AddQualityMeasureSheet")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.addQualityMeasureTracking(row)
                                }
                              }
                            },
                            [_vm._v("创建质量措施单")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }