var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderAdd" }, [
    _c("div", { staticClass: "container-left" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.type === "add"
              ? _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "view-list",
                        staticStyle: { "flex-flow": "row wrap" },
                        attrs: { type: "flex", justify: "start", gutter: 24 }
                      },
                      _vm._l(_vm.getBase, function(item, index) {
                        return _c(
                          "el-col",
                          {
                            key: item.prop,
                            style: { marginBottom: index !== 4 ? "20px" : 0 },
                            attrs: { span: 6 }
                          },
                          [
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(item.label) + "：")
                            ]),
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(_vm.getValue(item)))
                            ])
                          ]
                        )
                      }),
                      1
                    ),
                    _c("MFormBuilder", {
                      ref: "baseForm",
                      attrs: {
                        "label-position": "right",
                        "form-list": _vm.baseFormList,
                        "form-data": _vm.baseFormData
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "el-row",
                  {
                    staticClass: "view-list",
                    staticStyle: { "flex-flow": "row wrap" },
                    attrs: { type: "flex", justify: "start", gutter: 24 }
                  },
                  _vm._l(_vm.base, function(item, index) {
                    return _c(
                      "el-col",
                      {
                        key: item.prop,
                        style: { marginBottom: index !== 4 ? "20px" : 0 },
                        attrs: { span: 6 }
                      },
                      [
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(_vm._s(item.label) + "：")
                        ]),
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(_vm._s(_vm.getValue(item)))
                        ])
                      ]
                    )
                  }),
                  1
                )
          ],
          1
        )
      ]),
      _vm.type != "add"
        ? _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("质量措施")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _vm.type === "handle"
                  ? _c("MFormBuilder", {
                      ref: "baseForm",
                      attrs: {
                        "label-position": "right",
                        "form-list": _vm.measureFormList,
                        "form-data": _vm.baseFormData
                      }
                    })
                  : _c(
                      "el-row",
                      {
                        staticClass: "view-list",
                        staticStyle: { "flex-flow": "row wrap" },
                        attrs: { type: "flex", justify: "start", gutter: 24 }
                      },
                      _vm._l(_vm.qualityMeasureColumns, function(item, index) {
                        return _c(
                          "el-col",
                          {
                            key: item.prop,
                            style: { marginBottom: index !== 4 ? "20px" : 0 },
                            attrs: { span: 6 }
                          },
                          [
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(item.label) + "：")
                            ]),
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(_vm.getValue(item)))
                            ])
                          ]
                        )
                      }),
                      1
                    )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.type === "view"
        ? _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("关闭")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v(
                      "实际关闭时间：" +
                        _vm._s(_vm.detailData.actualClosingTime || "-")
                    )
                  ])
                ]),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v(
                      "关闭理由：" + _vm._s(_vm.detailData.closeReason || "-")
                    )
                  ])
                ])
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.type === "view"
        ? _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("跟进日志")]),
            _c(
              "div",
              { staticClass: "card-bot", staticStyle: { display: "block" } },
              [
                _c("MTable", {
                  ref: "pointCheckTable",
                  attrs: {
                    height: 300,
                    columns: _vm.followUpColumns,
                    "columns-setting": false,
                    "show-page": false,
                    data: _vm.followUpData
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      }
                    ],
                    null,
                    false,
                    442309118
                  )
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(_vm.type != "view" ? "取 消" : "返 回"))
            ]),
            _vm.type != "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.onSave()
                      }
                    }
                  },
                  [_vm._v("保 存")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }