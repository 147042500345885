<template>
    <div class="orderAdd">
      <div class="container-left">
        <div class="detail-card">
          <div class="card-tit">基本信息</div>
          <div class="card-bot">
            <div v-if="type === 'add'">
                <el-row type="flex" justify="start" style="flex-flow:row wrap" class="view-list" :gutter="24">
                <el-col
                v-for="(item, index) in getBase"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
                >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
                </el-col>
            </el-row>
                <MFormBuilder label-position="right" ref="baseForm" :form-list="baseFormList" :form-data="baseFormData"/>
            </div>
           
            <el-row v-else type="flex" justify="start" style="flex-flow:row wrap" class="view-list" :gutter="24">
                <el-col
                v-for="(item, index) in base"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
                >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
                </el-col>
            </el-row>
         
          </div>
        </div>
        <!-- 质量措施 -->
        <div class="detail-card" v-if="type != 'add'">
          <div class="card-tit">质量措施</div>
          <div class="card-bot">
            <MFormBuilder label-position="right" v-if="type === 'handle'" ref="baseForm" :form-list="measureFormList" :form-data="baseFormData"/>
            <el-row v-else type="flex" justify="start" style="flex-flow:row wrap" class="view-list" :gutter="24">
                <el-col
                v-for="(item, index) in qualityMeasureColumns"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
                >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
                </el-col>
            </el-row>
            </div>
        </div>
        <!-- 关闭 -->
        <div class="detail-card" v-if="type === 'view'">
          <div class="card-tit">关闭</div>
          <div class="card-bot">
            <el-col :span="6">
              <span class="bot-ti">实际关闭时间：{{ detailData.actualClosingTime || '-' }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">关闭理由：{{ detailData.closeReason || '-' }}</span>
            </el-col>
          </div>
        </div>
        <!-- 跟进日志 -->
        <div class="detail-card" v-if="type === 'view'">
          <div class="card-tit">跟进日志</div>
          <div class="card-bot" style="display: block">
            <MTable
            ref="pointCheckTable"
            :height="300"
            :columns="followUpColumns"
            :columns-setting="false"
            :show-page="false"
            :data="followUpData"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          </MTable>
            </div>
        </div>
        <div class="order-footer">
          <div/>
          <div class="footer-ri">
            <el-button @click="returnList">{{ type != 'view' ? '取 消' : '返 回' }}</el-button>
            <el-button type="primary" @click="onSave()" v-if="type != 'view'" :loading="loading">保 存</el-button>
          </div>
        </div>
    </div>
    </div>
  </template>
  <script>
  import api from '@/api/quality/quality-measure'
  import papi from '@/api/projectManagement/project'
  import { followUpColumns,base,qualityMeasureColumns } from './columns'
  import { baseFormList,measureFormList } from './form-list'
  import { setTreeData } from '@/utils'
  import uapi from '@/api/sets/user/user'
  
  export default {
    name: 'QualityMeasureTrackingDetail',
    data() {
      return {
        orderList: [],
        detailData: {
          source: 0
        },
        baseFormList,
        id: undefined,
        processList: [],
        loading:false,
        dialogFormVisible:false,
        comment:'',
        supplierList:[],
        resultId:'',
        supplierId:'',
        processType:0,
        btnName: '',
        supplierName:'',
        followUpData:[],
        followUpColumns,
        baseFormData: {
          code:''
        },
        saveButton:true,
        base,
        measureFormList,
        qualityMeasureColumns,
        type:''
      }
    },
    computed: {
      mode() {
      return this.$store.state.app.mode
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getBase() {
        return this.base.slice(0,11)
    }
  },
  created() {
    
    },
    async mounted(){
        this.type = this.$route.query.type
        if (this.$route.query.needInfo) {
          this.baseFormData =  JSON.parse(decodeURIComponent(this.$route.query.needInfo))
          this.baseFormData.sourceCode = this.baseFormData.number
          this.baseFormData.source = '不良品判定'
          this.baseFormData.sourceId = this.baseFormData.id
         await this.getCode()
          this.getUserList()
          this.getProblemLevelList()
          this.getProblemClassList()
          this.getDifficultyLevelList()
          this.getBusinessUnitList()
        }else{
          this.getOrderDetail(this.$route.query.id)
        }
    },
    methods: {
          // 获取业务部门
     async getBusinessUnitList() {
      const res = await uapi.getOrganizationLevelList()
        if (res) {
          this.baseFormList[3].props.options = setTreeData(res)
        }
      },
      async getCode() {
        const res = await api.getQualityMeasureTrackingCode()
        if(res){
          this.$set(this.baseFormData, 'code', res)
        }
      },
     getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.prop === 'improvedStatusName') {
        return '0/4'
      }
      return result || '-'
    },
    async getProblemClassList() {
      const res = await api.getProblemClassList()
      if(res){
        this.baseFormList[0].children[0].options = res
      }
    },
    async getProblemLevelList() {
      const res = await api.getProblemLevelList()
      if(res){
        this.baseFormList[6].children[0].options = res
      }
    },
    async getDifficultyLevelList() {
      const res = await api.getDifficultyLevelList()
      if(res){
        this.baseFormList[7].children[0].options = res
      }
    },
      async onSave(){
        const { form } = this.$refs.baseForm
        let obj
        let qapi
        if(this.type === 'add'){
          qapi = api.addQualityMeasureTracking
          obj = {
          code: form.code, //跟踪单号
          sourceId: form.sourceId, //来源对象
          source: form.source, //来源对象
          sourceCode: form.sourceCode, //来源单号
          procedureId: form.procedureId, //工序id
          materialsId: form.materialsId, //物料id
          batchNumber: form.batchNumber, //批次码
          sequenceNumber: form.sequenceNumber, //序列码(逗号拼接)
          supplierId: form.supplierId, //供应商id
          supplierName: form.supplierName, //供应商名称（若无供应商id，则填名称）
          // improvedStatusId: form.improvedStatusId, //改善状态id
          problemClassId: form.problemClassId, //问题分类id
          problemDesc: form.problemDesc, //问题描述
          systemName: form.systemName, //系统名称
          organizationId: (form.organizationId || []).at(-1), //责任部门id
          principalId: form.principalId, //负责人id
          keyProblem: form.keyProblem, //重点问题
          problemLevelId: form.problemLevelId, //问题等级id
          difficultyLevelId: form.difficultyLevelId, //难易等级id
          frequency: form.frequency, //频次
          batchRisk: form.batchRisk, //批量风险：有，无
          laborMaterialWaste: form.laborMaterialWaste, //工料废：工废、料废
          returnTime: form.returnTime, //退回时间
          remark: form.remark //备注
        }
        }else{
          qapi = api.handleTrackingSheet
          obj = {
            id:this.$route.query.id, //跟踪单id
            containmentMeasures:form.containmentMeasures, //遏制措施
            implementTime: form.implementTime, //实施时间
            causeAnalysis: form.causeAnalysis, //原因分析
            tempMeasure: form.tempMeasure, //临时措施
            tempFinishTime: form.tempFinishTime, //临时完成时间
            permanentMeasure: form.permanentMeasure, //永久措施
            permanentFinishTime: form.permanentFinishTime, //永久完成时间
            factoryBreakpoint: form.factoryBreakpoint, //工厂断点
            supplierBreakpoint: form.supplierBreakpoint //供应商断点
        }
        }

        console.log(obj)
        this.$refs.baseForm.formRefs().validate(async(valid) => {
          if (valid) {
              this.loading = true
              const res = await qapi(obj)
              if (res) {
                this.loading = false
                this.$message.success('保存成功')
                this.$router.push({
                  name: 'QualityMeasureTrackingList'
                })
              }
              this.loading = false
            }
          })
      },
           // 获取负责人
    async getUserList() {
      const res = await papi.getResponsible()
      if (res) {
        this.baseFormList[4].children[0].options = res
      }
    },
      async getOrderDetail(id) {
        const res = await api.getTrackingSheetDetail({id:id})
        if (res) {
          this.detailData = res
          this.baseFormData = res
          this.followUpData = res.followLogs ? res.followLogs : []
        }
      },
        returnList() {
          this.$router.go(-1)
        }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .orderAdd{
    ::v-deep {
    .el-form-item {
      margin-bottom: 20px !important;
      }
    }
  .container-left {
    height: 100%;
    box-sizing: border-box;
  }

  .container-right {
    width: 25%;
    height: 100%;
    box-sizing: border-box;

    .description {
      margin-top: 8px;

      div {
        line-height: 40px;
        background: #f5f7f9;
        padding-left: 10px;
        color: #67687d;
        border-bottom: 1px solid #e4e6e8;
        font-size: 14px;
      }
    }
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;

    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;

      span {
        margin-right: 45px;
        display: inline-block;
      }
    }

    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  }
  .bot-li {
    display: flex;
    justify-content: flex-start;
    .bot-ti {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 15px;
    }
  }
  .el-tags {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .icon-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 19px;
    height: 19px;
    display: block;
  }
  .all-ipt {
    margin-top: 4px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .card-bot {
    display: flex;
    flex-flow: row wrap;
    .bot-ti {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 16px;
      word-break: break-all;
    }
  }
  .tables {
  padding: 20px;
}
  </style>
  