
export const base = [
  {
    prop: 'code',
    label: '跟踪单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'source',
    label: '来源对象',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sourceCode',
    label: '来源单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '工序',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 120,
    // form: {
    //   tagName: 'el-select',
    //   options: status,
    //   defaultValue: 1
    // }
  },
  {
    prop: 'materialsSpecifications',
    label: '物料规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'supplierName',
    label: '供应商',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'improvedStatusName',
    label: '改善状态',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'problemClassName',
    label: '问题分类',
    sortable: false,
    hiddenSearch: true,
    form: {
      key:'problemClassId',
      tagName: 'el-select',
      optionsProps: {
        label: 'problemClass',
        value: 'id'
      },
      required: true
    }
  },
  {
    prop: 'problemDesc',
    label: '问题描述',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: true
    }
  },
  {
    prop: 'systemName',
    label: '系统名称',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false
    }
  },
  {
    prop: 'organizationName',
    label: '责任部门',
    sortable: false,
    hiddenSearch: true,
    form: {
      required: false,
      tagName: 'el-cascader',
      key: 'organizationId',
      props: {
        disabled: false,
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    }
    // form: {
    //   tagName: 'el-select',
    //   key:'organizationId',
    //   required: false
    // }
  },
  {
    prop: 'principalName',
    label: '责任人',
    sortable: false,
    hiddenSearch: true,
    form: {
      key:'principalId',
      tagName: 'el-select',
      optionsProps: {
        label: 'userFullName',
        value: 'id'
      },
      options: [],
      required: true
    }
  },
  {
    prop: 'keyProblem',
    label: '重点问题',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false
    }
  },
  {
    prop: 'problemLevelName',
    label: '问题等级',
    sortable: false,
    hiddenSearch: true,
    form: {
      key:'problemLevelId',
      tagName: 'el-select',
      optionsProps: {
        label: 'level',
        value: 'id'
      },
      required: true
    }
  },
  {
    prop: 'difficultyLevelName',
    label: '难易等级',
    sortable: false,
    hiddenSearch: true,
    form: {
      key:'difficultyLevelId',
      tagName: 'el-select',
      optionsProps: {
        label: 'level',
        value: 'id'
      },
      required: true
    }
  },
  {
    prop: 'frequency',
    label: '频次',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input-number', props: { controlsPosition: 'right', min: 0, placeholder: '请输入频次' }, required: false}
  },
  {
    prop: 'batchRisk',
    label: '批量风险',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      options: [
        {
          id: '有',
          name: '有'
        },
        {
          id: '无',
          name: '无'
        }
    ],
      required: false
    }
  },
  {
    prop: 'laborMaterialWaste',
    label: '工料废',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      options: [
        {
          id: '工废',
          name: '工废'
        },
        {
          id: '料废',
          name: '料废'
        }
    ],
      required: false
    }
  },
  {
    prop: 'returnTime',
    label: '退回时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-date-picker',
      required: false,
       props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    prop: 'requiredClosingTime',
    label: '要求关闭时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'reformLimitTime',
    label: '整改期限（天）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  }
]
// 质量措施
export const qualityMeasureColumns = [
    {
        prop: 'containmentMeasures',
        label: '遏制/围堵措施',
        sortable: false,
        hiddenSearch: true,
        form: {
            tagName: 'el-input',
            required: false,
            // props: { type: 'textarea' }
        }
    },
    {
        prop: 'implementTime',
        label: '实施时间',
       sortable: false,
       form: {
        tagName: 'el-date-picker',
        required: false,
         props: {
          align: 'center',
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          format: 'yyyy-MM-dd HH:mm'
        }
      }
    },
    {
        prop: 'causeAnalysis',
        label: '原因分析',
       sortable: false,
       form: {
           colSpan: 24,
           tagName: 'el-input',
           required: false
       }
    },
    {
        prop: 'tempMeasure',
        label: '临时措施',
       sortable: false,
       form: {
           tagName: 'el-input',
           required: false
       }
    },
    {
        prop: 'tempFinishTime',
        label: '临时完成时间',
       sortable: false,
       form: {
        tagName: 'el-date-picker',
        required: false,
         props: {
          align: 'center',
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          format: 'yyyy-MM-dd HH:mm'
        }
      }
    },
    {
        prop: 'permanentMeasure',
        label: '永久措施',
       sortable: false,
       form: {
           tagName: 'el-input',
           required: false
       }
    },
    {
        prop: 'permanentFinishTime',
        label: '永久完成时间',
       sortable: false,
       form: {
        tagName: 'el-date-picker',
        required: false,
         props: {
          align: 'center',
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          format: 'yyyy-MM-dd HH:mm'
        }
      }
    },
    {
        prop: 'factoryBreakpoint',
        label: '工厂断点',
       sortable: false,
       form: {
           tagName: 'el-input',
           required: false
       }
    },
    {
        prop: 'supplierBreakpoint',
        label: '供应商断点',
       sortable: false,
       form: {
           tagName: 'el-input',
           required: false
       }
    }
]
export const followUpColumns = [
    {
        prop: 'index',
        label: '序号',
        sortable: true,
        slotName: 'index',
        hiddenSearch: true,
        width: 60
    },
    {
      prop: 'createTime',
      label: '跟进时间',
      sortable: false,
      hiddenSearch: true,
    },
    {
      prop: 'followLog',
      label: '跟进记录',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'creator',
      label: '跟进人',
      sortable: false,
      hiddenSearch: true
    }
  ]