var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrap-wrap" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForm", {
            ref: "searchForm",
            attrs: {
              "form-list-extend": _vm.searchFormList,
              "form-data": _vm.searchFormData,
              searchType: 1
            },
            on: {
              "update:formData": function($event) {
                _vm.searchFormData = $event
              },
              "update:form-data": function($event) {
                _vm.searchFormData = $event
              },
              search: _vm.searchTable
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("FunctionHeader", {
            ref: "functionHeader",
            attrs: {
              "search-title": "请输入跟踪单号",
              tags: _vm.functionHeader.tags,
              "export-name": _vm.functionHeader.exportName,
              "export-params": _vm.functionHeader.exportParams
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columns,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "sourceCode",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: _vm.getDetailPath(row, "view"),
                      text: row.sourceCode
                    }
                  })
                }
              },
              {
                key: "sequenceNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            "popper-class": "sequence-tooltip",
                            content: row.sequenceNumber,
                            placement: "top-start"
                          }
                        },
                        [
                          _c("span", { staticClass: "mr-10" }, [
                            _vm._v(" " + _vm._s(row.sequenceNumber) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.permission("Detail")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.viewDetail(row, "view")
                                }
                              }
                            },
                            [_vm._v("详情")]
                          )
                        : _vm._e(),
                      _vm.permission("Detail")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission("Handle")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", disabled: !row.canHandle },
                              on: {
                                click: function($event) {
                                  return _vm.viewDetail(row, "handle")
                                }
                              }
                            },
                            [_vm._v("处理")]
                          )
                        : _vm._e(),
                      _vm.permission("Handle")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission("Close")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", disabled: !row.canClose },
                              on: {
                                click: function($event) {
                                  return _vm.close(row)
                                }
                              }
                            },
                            [_vm._v("关闭")]
                          )
                        : _vm._e(),
                      _vm.permission("Close")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission("Follow")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.addFollowUp(row)
                                }
                              }
                            },
                            [_vm._v("跟进记录")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "关闭" },
          on: { onOk: _vm.submitCloseForm },
          model: {
            value: _vm.stationVisible,
            callback: function($$v) {
              _vm.stationVisible = $$v
            },
            expression: "stationVisible"
          }
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { placeholder: "关闭理由", type: "textarea", rows: 2 },
                model: {
                  value: _vm.closeReason,
                  callback: function($$v) {
                    _vm.closeReason = $$v
                  },
                  expression: "closeReason"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "新增跟进记录" },
          on: { onOk: _vm.submitFollowUpForm },
          model: {
            value: _vm.followUpVisible,
            callback: function($$v) {
              _vm.followUpVisible = $$v
            },
            expression: "followUpVisible"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-bottom": "10px" } },
            [
              _c(
                "el-form",
                {
                  ref: "followUpBuild",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.followUpForm,
                    "label-width": "80px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "改善状态",
                        prop: "improvedStatusId",
                        tyle: "margin-bottom: 20px"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.followUpForm.improvedStatusId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.followUpForm,
                                "improvedStatusId",
                                $$v
                              )
                            },
                            expression: "followUpForm.improvedStatusId"
                          }
                        },
                        _vm._l(_vm.improveStateList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.improvedStatus,
                              value: item.id
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { label: "跟进记录", prop: "followLog" }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 2 },
                        model: {
                          value: _vm.followUpForm.followLog,
                          callback: function($$v) {
                            _vm.$set(_vm.followUpForm, "followLog", $$v)
                          },
                          expression: "followUpForm.followLog"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }